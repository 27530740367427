<template>
  <v-app id="terms">
    <v-main>
      <v-container fluid fill-height>
      </v-container>
    </v-main>
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/Footer'

export default {
  name: 'terms',
  components: { mainFooter },
  data: () => ({
  }),
  methods: {
    back() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
</style>
